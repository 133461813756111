import { StrapiService } from '@/services/codegen-welfare';
import * as Sentry from '@sentry/react';
const brandConfigurationParams = [
  '*',
  'theme.favicon',
  'theme.logoPrimaryUrl',
  'theme.logoSecondaryUrl',
];

const brandConfigurationParamsString = brandConfigurationParams
  .map((value, index) => `populate[${index}]=${value}`)
  .join('&');

const hostname = window._env_.VITE_DOMAIN_STRAPI || window.location.hostname;

export const getBrandConfig =
  await StrapiService.getApiServicesAppStrapiGetConfig({
    path: `free-flight-configurations/${hostname}?${brandConfigurationParamsString}`,
  })
    .then(async (result) => result.data.attributes)
    .catch((error) => {
      Sentry.captureException(error);
      return null;
    });
