import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 330px;
`;

export const Title = styled.span`
  display: flex;
  justify-content: center;
  color: white;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 38px;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 4em 16px 4em 16px;

  @media (min-width: 576px) {
    max-width: 540px !important;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;
