import { useStore } from '@/store/useStore';
import { Button } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as Styled from './MainContent.styled';

export function MainContent() {
  const { t } = useTranslate();

  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const translationkeys = brandConfig.content;

  const { secondaryColor } = brandConfig.theme.data.attributes;

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.Title>
          {t(translationkeys.content.steps_to_follow.title)}
        </Styled.Title>
        <Styled.StepsContainer>
          {translationkeys.content.steps_to_follow.steps.map((e, index) => (
            <Styled.Box key={index}>
              <Styled.Title style={{ color: secondaryColor }}>
                {index + 1}
              </Styled.Title>
              <Styled.Description
                dangerouslySetInnerHTML={{
                  __html: t(e.key, { ...e.placeholders }),
                }}
              ></Styled.Description>
            </Styled.Box>
          ))}
        </Styled.StepsContainer>
        <Styled.Title>
          {t(translationkeys.content.promo_conditions.title)}
        </Styled.Title>
        <Styled.ConditionsList>
          {translationkeys.content.promo_conditions.steps.map((e, index) => (
            <li
              key={`promo_conditions_${index}`}
              dangerouslySetInnerHTML={{
                __html: t(e.key, { ...e.placeholders }),
              }}
            />
          ))}
        </Styled.ConditionsList>
        <Styled.ContainerBox>
          <Styled.Title>{t(translationkeys.content.rules.title)}</Styled.Title>
          <Styled.Description>
            {t(translationkeys.content.rules.description)}
          </Styled.Description>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(translationkeys.content.rules.cta.url, '_blank')
              }
            >
              {t(translationkeys.content.rules.cta.key)}
            </Button>
          </div>
        </Styled.ContainerBox>
        {translationkeys.content.information_and_assistance && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Styled.Title>
              {t(translationkeys.content.information_and_assistance.title)}
            </Styled.Title>
            <Styled.Description>
              <b
                dangerouslySetInnerHTML={{
                  __html: t(
                    translationkeys.content.information_and_assistance
                      .description.key,
                    {
                      ...translationkeys.content.information_and_assistance
                        .description.placeholders,
                    }
                  ),
                }}
              />
            </Styled.Description>
            <Styled.LegalText>
              {t(
                translationkeys.content.information_and_assistance.legal_message
              )}
            </Styled.LegalText>
          </div>
        )}
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
